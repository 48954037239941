import * as React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Seo from "../components/seo"

import {HomeSplash, RowWrapper, TextCenter} from "./contact.module.css"

const GMaps =
				"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18959.212745696812!2d-114.49217071446122!3d53.55952412153903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x539fa5d5e65a6781%3A0xb8896683b598f8d8!2sWabamun%20%26%20District%20Museum!5e0!3m2!1sen!2sca!4v1620757039808!5m2!1sen!2sca" // google maps embed

const GStyle = {
				border: `0`,
}

const JotformUrl = "https://form.jotform.com/201845505840251" //Jotform embed URL from Iframe

const IStyle = {
				width: `100%`,
				height: `720px`,
				overflow: `hidden`, // hide scrolls
				scrolling: `no`,
				border: `none`,
				margin: `0 0 3em 0`,
				padding: `0`,
}

const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
`

const Contact = () => (
				<Layout>
    <Seo
				    title="Contact FenceLink"
				    description="Residential, Commercial, Repairs &amp; Auger Services"
    />
    <div className={HomeSplash}>
      <iframe
				      title="FenceLink"
				      src={GMaps}
				      width="600"
				      height="500"
				      frameBorder="0"
				      style={GStyle}
				      aria-hidden="false"
				      disable="geolocation; microphone; camera" />
    </div>

    <div className={RowWrapper} style={{marginTop: `3em`}}>
      <Heading>Fence Link</Heading>
      <p className={TextCenter}>
        Please reach out if you have any questions
        <br />
        Alternatively, you can call us at{""}
				      <a href="tel:+17809744958" title="Contact fence link">
          780.974.4958
        </a>
      </p>
    </div>

								{/* main text */}
								<div className={RowWrapper}>
      <iframe
				      title="contact form"
				      style={IStyle}
				      src={JotformUrl}
				      scrolling="no"
				      allowFullScreen="true"
				      allowTransparency="true"

				      // todo: check deploy for build errors in contact form
				      onLoad={() => {
								      window.parent.scrollTo(0, 0)
				      }}
      />
    </div>
  </Layout>
)

export default Contact
